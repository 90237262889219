.container {
  display: grid;
  height: calc(var(--circleDiameter) * 1.6);
  place-items: center;
  overflow: hidden;
  --circleDiameter: 400px;
  --planets: calc(var(--circleDiameter) / 3);

  @media screen and (max-width: 600px) {
    --circleDiameter: 15em;
    height: calc(var(--circleDiameter) * 1.75);
    --planets: calc(var(--circleDiameter) / 2.8);
  }

  @media screen and (max-width: 325px) {
    --circleDiameter: 13em;
    --planets: calc(var(--circleDiameter) / 2.5);
  }
}

.hero-circle {
  position: relative;
  width: var(--circleDiameter);
  height: var(--circleDiameter);
}

@media screen and (max-width: 426px) {
  .hero-circle {
    width: var(--circleDiameter);
    height: var(--circleDiameter);
  }
}

.inner-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}

.hero-rotate {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 7px solid white;
  border-radius: 100%;
  animation: rotate 40s linear infinite;
}

.content {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 0.4em;
  font-family: Barlow;
  text-transform: uppercase;
  justify-content: center;
  font-weight: 600;
  padding: 10px;
}

.planet {
  background-color: #ba9451;
  position: absolute;
  border: 1px solid white;
  width: var(--planets);
  aspect-ratio: 1;
  border-radius: 100%;
  animation: maintain 40s linear infinite;
}

.center-planet {
  position: relative;
  top: 55%;
  left: 0;
  font-family: Barlow;
  text-transform: uppercase;
}

.planet:nth-child(1) {
  top: calc(-1 * var(--planets) / 2);
  left: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
}

.planet:nth-child(2) {
  top: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
  right: calc(-1 * var(--planets) / 2);
}

.planet:nth-child(3) {
  top: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
  left: calc(-1 * var(--planets) / 2);
}

.planet:nth-child(4) {
  left: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
  bottom: calc(-1 * var(--planets) / 2);
}

.planet>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 5px solid white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  animation: scale 1s linear infinite alternate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes maintain {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}