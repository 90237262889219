main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  width: 14em;
  height: 16em;
  line-break: auto;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #d3d3d3;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1025px) {
    width: 16em;
    height: 18em;
  }
  @media screen and (max-width: 800px) {
    width: 12.5em;
    height: 16em;
  }
  @media screen and (max-width: 500px) {
    width: 19em;
    height: 400px;
  }

  @media screen and (max-width: 400px) {
    width: 17em;
    height: 350px;
  }
  @media screen and (max-width: 330px) {
    width: 16em;
    height: 320px;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;

    filter: blur(2px);
    background-color: #ffffffba;
    transition: opacity 0.2s ease-out;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.449);
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
  }

  h2 {
    position: absolute;
    inset: auto auto 30px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: Barlow;
    font-size: x-large;
    text-align: left;
    font-weight: 800;
    width: 80%;
    text-transform: uppercase;
    color: #162c33;
  }

  p,
  a {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    inset: auto auto 80px 30px;
    font-family: Barlow;
    font-size: large;
    text-align: left;
    @media screen and (max-width: 800px) {
      font-size: medium;
    }
  }
  button: {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }
  a {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }
  &:hover .overlay {
    background-color: #ffffff00;
    transition: background-color 0.3s ease-out;
  }
  &:hover h2 {
    color: #e5e5e5;
    inset: auto auto 220px 30px;
    transition: inset 0.3s ease-out;
    @media screen and (max-width: 500px) {
      inset: auto auto 55% 30px;
    }
    @media screen and (max-width: 400px) {
      inset: auto auto 65% 30px;
    }
  }

  &:hover p,
  &:hover a {
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }

  &:hover img {
    transition: opacity 0.3s ease-in;
    opacity: 0.3;
  }
}

.material-symbols-outlined {
  vertical-align: middle;
}
