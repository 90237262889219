@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500&family=Nunito:wght@300;400;500;600;700;800&display=swap');
.App {
  text-align: center;
  background-color: #172a33;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #d9d9d9;
  font-family: 'Nunito', serif;
  overflow-x: hidden;
}

.body {
  font-family: 'Barlow';
}
