.text-post {
  text-align: justify;
  font-family: "Barlow";
  margin-top: 0.5rem;
  font-weight: 400;
  color: "white";
  font-size: 0.75em;
}
.text-post-italic {
  text-align: justify;
  font-family: "Barlow";
  margin-top: 0.5rem;
  font-weight: 400;
  color: "white";
  font-size: 0.75em;
  font-style: italic;
}
.btn-back {
  position: absolute;
  top: 13%;
  left: 8%;
  border-radius: 50px;
  background-color: #233c45;
  padding: 8px 10px;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-back:hover {
  background-color: #3e5b6a;
  transform: scale(1.05);
}

@media (max-width: 1200px) {
  .btn-back {
    left: 9%;
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .btn-back {
    left: 6%;
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .btn-back {
    left: 3%;
    font-size: 0.75rem;
  }
}

@media (max-width: 576px) {
  .btn-back {
    top: 15%;
    left: 5%;
    font-size: 0.7rem;
  }
}
