main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-introduction {
  width: -webkit-fill-available;
  height: 350px;
  line-break: auto;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #d3d3d3;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1600px) {
    height: 300px;
  }
  @media screen and (max-width: 1000px) {
    width: 50vw;
  }
  @media screen and (max-width: 600px) {
    width: 70vw;
  }
  @media screen and (max-width: 400px) {
    width: 80vw;
  }

  .img {
    object-fit: cover;
    inset: auto auto 100% 0%;
    margin: 0;
    transition: inset 0.6s ease-in-out;
    opacity: 1;
    transition: opacity 0.3s 0.1s ease-in;
  }

  .title {
    position: absolute;
    inset: auto auto 100% 0%;
    bottom: 3.5em;
    margin: 0;
    transition: inset 0.3s ease-out;
    font-family: Barlow;
    font-size: xx-large;
    text-align: center;
    font-weight: 600;
    width: 100%;
    color: #162b33;
    text-transform: uppercase;

    @media screen and (max-width: 1500px) {
      font-size: x-large;
    }
  }

  .paragraph {
    position: absolute;
    opacity: 0;
    max-width: 100%;
    transition: opacity 0.3s ease-out;
  }

  .paragraph {
    overflow: hidden;
    inset: auto auto 100% 00%;
    font-family: Barlow;
    font-size: large;
    text-align: center;
    @media screen and (max-width: 1200px) {
      font-size: medium;
    }
  }

  &:hover .title {
    inset: auto auto 65% 0%;
    transition: inset 0.3s ease-out;
    color: #ba9451;
    @media screen and (max-width: 1500px) {
      inset: auto auto 75% 0%;
    }
    @media screen and (max-width: 1000px) {
      inset: auto auto 70% 0%;
    }
    @media screen and (max-width: 600px) {
      inset: auto auto 65% 0%;
    }
    @media screen and (max-width: 450px) {
      inset: auto auto 73% 0%;
    }
    @media screen and (max-width: 350px) {
      inset: auto auto 75% 0%;
    }
  }
  &:hover .paragraph {
    opacity: 1;
    inset: auto auto 25% 0%;
    transition: opacity 0.5s 0.1s ease-in;
    color: #162b33;
    @media screen and (max-width: 1700px) {
      inset: auto auto 15% 0%;
    }
    @media screen and (max-width: 1060px) {
      inset: auto auto 10% 0%;
    }
    @media screen and (max-width: 1000px) {
      inset: auto auto 25% 0%;
    }

    @media screen and (max-width: 600px) {
      inset: auto auto 20% 0%;
    }
    @media screen and (max-width: 350px) {
      inset: auto auto 10% 0%;
    }
  }

  &:hover .img {
    transition: opacity 0.3s ease-in;
    opacity: 0;
  }
}

.material-symbols-outlined {
  vertical-align: middle;
}
